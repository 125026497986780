import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Navigation from './components/Navigation';


function Blog() {

  return (
   <>
   <Container>
    <Navigation />
      {/* <div style={{ width: "680px", margin: "0 auto", paddingTop: "100px"}}>
        <h1 style={{ padding: "20px 0px", fontSize: "38px", fontFamily: "serif"}}>The Art of Meta-Scheduling: Why Planning Your Planning Is the Future</h1>
        <h4 style={{ fontSize: "20px", color: "rgba(0,0,0,0.5)"}}>
        Time is short. Here are the creative benefits of becoming an obsessive planner.
        </h4>
        <img 
                    src={require("./assets/img/blog1.png")} alt="browser window"
                    className="img centeredShadow d-none d-md-block"
                    style={{ width: "100%", paddingTop: "50px"}}
                /> 
        <div style={{ display: "flex", flexDirection: "row", padding: "50px 0px"}}>
            <div style={{ height: "60px", width: "60px", borderRadius: "100px", overflow: "hidden"}}>
                <img 
                    src={require("./assets/img/testimonial_2.png")} alt="browser window"
                    style={{ height: "60px" }}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", marginTop: "5px"}}>
                <p style={{ padding: 0, margin: 0, textAlign: "left" }}>Nick Kotowski</p>
                <p style={{ padding: 0, margin: 0, textAlign: "left", color: "rgba(0,0,0,0.5)"}}>3min read · published 06.01.2024</p>
            </div>
        </div>
        <p style={{ fontSize: "18px"}}>
        In a letter to his fiancée Felice Bauer, Franz Kafka once wrote, “time is short, my strength is limited, the office is a horror, the apartment is noisy, and if a pleasant, straightforward life is not possible then one must try to wriggle through by subtle maneuvers.”
        </p>
        <p style={{ fontSize: "18px"}}>
        One of Kafka’s strategies for “wriggling through” — balancing a full-time day job with his writing aspirations — was sticking to a consistent, albeit unhealthy, schedule. He would start writing around 11 pm and continue until one, two, three, or even six in the morning. He’d sleep for a few hours, work, have lunch, take another nap, and get to writing again. In Daily Rituals: How Artists Work, author Mason Currey explores Kafka’s and various other prolific thinkers’ routines. Taken together, these rituals show that productive creatives don’t wait around for inspiration to strike. They take a proactive approach to planning and fiercely protect their hours for creativity.
        </p>
        <p style={{ fontSize: "20px", fontWeight: "900", marginTop: "50px"}}>
        The benefits of meta-scheduling
        </p>
        <p style={{ fontSize: "18px"}}>
        Certain parts of my schedule have become second nature. Every morning, I exercise, have a quick breakfast, and head to the office. The first thing I do is write my morning pages — thirty or so minutes during which I jot down anything in my head in a stream-of-consciousness manner. Afterward, I take around 15 minutes to map out the rest of my day.
        </p>

      </div> */}
      
    </Container>
   
       
   </>
  );
}

export default Blog;
