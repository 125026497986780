// Footer.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const footerColumn = {

}

function Footer() {
  return (
    <div style={{ backgroundColor: "rgb(20,20,20)", padding: "20px 0px"}}>
          <Row>
            <Col lg={6} md={12}>
              <p 
                className="fontPoppins p-0 m-0"
                style={{ fontSize: "12px", color: "#5E5E5E", width: "100%", textAlign: "center" }}
                onClick={() => console.log("navigate")}
              >Copyright © Ceevie 2024 | by Studio Endorfinas S.L.</p>
            </Col>
            <Col lg={6} md={12} style={{ display: "flex", flexDirection: "row"}}>
              <p 
                className="fontPoppins p-0 m-0 pointer" 
                style={{ fontSize: "12px", color: "#5E5E5E", flex: 1, textAlign: "center" }}>
                  <span 
                    onClick={() => console.log("navigate")}
                  >
                    <a style={{ textDecoration: "none", color: "#5E5E5E"}} href="https://ceevie.com/privacy">Privacy Agreement</a>
                  </span>
                  <span> | </span>
                  <span>
                    <a style={{ textDecoration: "none", color: "#5E5E5E"}} href="https://ceevie.com/terms-and-conditions">Terms and Conditions</a>
                  </span></p>
              </Col>
          </Row>
        </div>
  );
}

export default Footer;