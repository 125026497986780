
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navigation() {
  
  return (
   <>
    <div className="navigationBarContainer d-none d-lg-block">
        <div className="navigationBar">
          <div className="navigationBarLogo">
            <img 
                src={require("../assets/img/ceevie_logo_black.png")} alt="browser window"
                style={{ height: "39px",}}
              />

          </div>
          <div className="navigationBarLinks">
            <p className="navigationBarLink">Blog</p>
            <p className="navigationBarLink">Product</p>
            <p className="navigationBarLink">About</p>
            <p className="navigationBarLink">Pricing</p>
            <p className="navigationBarLink">FAQs</p>
          </div>
          <div>
            <p style={{ backgroundColor: "black", color: "white", padding: "12px 20px", borderRadius: "100px", fontWeight: "600" }}><a style={{ textDecoration: "none", color: "white"}} href="https://account.ceevie.com/#/sign-up">Sign Up</a></p>
          </div>
        </div>
      
      </div>
      <div className="navigationBarContainer  d-block d-lg-none">
        <div className="navigationBar" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div className="navigationBarLogo">
            <img 
                src={require("../assets/img/ceevie_logo_black.png")} alt="browser window"
                style={{ height: "39px", position: "absolute", top: "24px", left: "24px"}}
              />

          </div>
          
         
        </div>
       
       
      
      </div>
    </>
    
  );
}

export default Navigation;
