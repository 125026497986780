import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faGithub, faInstagram, faTwitter, faLinkedin, faBehance } from '@fortawesome/free-brands-svg-icons';


function Screen2() {

    return (
        <div className="d-block d-lg-none">
           <img 
                src={require("../assets/img/iphone_1.png")} alt="browser window"
                style={{ height: "500px", paddingTop: "50px" }}
            />

        </div>
        
      

    );
}

export default Screen2;