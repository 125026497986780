import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function CallToAction() {

  return (
   <>
        <div style={{ width: "100vw", backgroundColor: "#D5EC8B" }}>
           
            <img 
                    src={require("../assets/img/ceevie_logo_black.png")} alt="browser window"
                    style={{ height: "59px", backgroundColor: "white", marginTop: "10vh", width: "187px", padding: "10px 30px", borderRadius: "50px"}}
                    className="d-none d-lg-block"
                />
            <Row className="heroTitleContainer">
                <Col lg={7}>
                    <div className="d-none d-lg-block" style={{ width: "100vw", height: "10vh"}}/>
                    <p className="heroTitle d-none d-lg-block" style={{ fontSize: "54px", lineHeight: "54px", fontWeight: 800}}>Video CVs get 37% more interview invites</p>
                    <p className="heroSubtitle d-none d-lg-block" style={{ marginTop: "20px"}}>Are you ready to boost your chances? <span><a style={{ color: "black"}}href="https://account.ceevie.com/#/sign-up">Sign up</a></span> today 📅!</p>
                    <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "34px", marginTop: "30px", fontWeight: 800, textAlign: "left", marginLeft: "12px", marginRight: "24px"}}>Video CVs get 37% more interview invites</p>
                    <p className="heroSubtitle d-block d-lg-none" style={{ marginTop: "20px", textAlign: "left", marginLeft: "12px", marginRight: "24px"}}>Are you ready to boost your chances? <span><a style={{ color: "black"}}href="https://account.ceevie.com/#/sign-up">Sign up</a></span> today 📅!</p>

                    <div style={{ display: "flex", flexDirection: "row", width: "210px", margin: "0 auto", paddingTop: "25px"}} className="shake">
                        <div style={{  display: "flex", flexDirection: "row", backgroundColor: "black", width: "170px", borderRadius: "100px", margin: "0 auto"}}>
                            <div style={{ width: "40px", height: "40px", backgroundColor: "white", paddingTop: "6px", paddingLeft: "3px", borderRadius: "50px", margin: "5px 5px 5px 5px" }}>
                                <img 
                                    src={require("../assets/img/001-right-arrow.png")} alt="downward arrow"
                                    style={{ width: "20px", filter: "grayscale(100%)"}}
                                    className="img pointer"
                                />
                            </div>
                            <p 
                                style={{margin: "0 auto", height: "50px", paddingTop: "10px", fontWeight: "700", fontSize: "20px", color: "white", marginLeft: 0,}}
                            >
                                    <a 
                                        style={{ color: "white", textDecoration: "none"}}
                                        href="https://account.ceevie.com/#/sign-up"
                                    >
                                            Boost Now!
                                    </a>
                                    
                            </p>
                        </div>
                    </div>

                    <p className="d-none d-lg-block" style={{ fontSize: "12px", opacity: "50%", margin: 0, padding: 0, paddingTop: "100px", paddingBottom: "10px"}}><a style={{ color: "black", textDecoration: "none"}}href="https://docs.iza.org/dp13656.pdf">Link to study</a></p>
                    <p className="d-block d-lg-none" style={{ fontSize: "12px", opacity: "50%", margin: 0, padding: 0, paddingTop: "20px", paddingBottom: "10px"}}><a style={{ color: "black", textDecoration: "none"}}href="https://docs.iza.org/dp13656.pdf">Link to study</a></p>

                </Col>
            </Row>
        </div>
   </>
  );
}

export default CallToAction;
