import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Analytics() {

  return (
   <>
    <Container>
        <Row className="heroTitleContainer" style={{ padding: "0px 0px 0px 0px"}}>
            <Col lg={12}>
                <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", fontWeight: "800"}}>Smart <span className="highlight">Analytics</span>,...</p>
                <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "50px", fontWeight: "800", textAlign: "left"}}>Smart<span className="highlight">Analytics</span>,</p>

                <p className="heroSubtitle d-none d-lg-block" style={{ margin: 0, marginTop: "20px", fontSize: "18px", padding: "0vw 15vw"}}>
                ... smarter career moves. Ceevie isn't just about getting noticed; it's about taking control of your career journey.
                Embrace the power of insights, stand out, and be fully prepared to dazzle in your interviews. Join us today and make your job search smarter and more successful!
                </p>
                <p className="heroSubtitle d-block d-lg-none" style={{ margin: 0, marginTop: "20px", fontSize: "18px", padding: "0vw", textAlign: "left"}}>
                ... smarter career moves. Ceevie isn't just about getting noticed; it's about taking control of your career journey.
                Embrace the power of insights, stand out, and be fully prepared to dazzle in your interviews. Join us today and make your job search smarter and more successful!
                </p>
            </Col>
            <div style={{ height: "100px"}} className="d-none d-lg-block"/>
        </Row>
    </Container>
    <p className="d-block d-lg-none" style={{ padding: 0, marginLeft: "24px", paddingTop: "50px", textAlign: "center"}}>Scroll to the right >></p>

    <div style={{ overflowX: "scroll", margin: "24px 0px 0px 24px", paddingRight: "24px"}} className="d-block d-lg-none">
        <img 
            src={require("../assets/img/browserBar.png")} alt="browser window"
            className="img"
            style={{ width: "600px"}}
        /> 
        <img 
            src={require("../assets/img/analytics_dashboard.webp")} alt="browser window"
            className="img"
            style={{ width: "600px"}}
        /> 

    </div>

    <Container>
        <Row>
        <Col lg={8} style={{ padding: "20px"}} className="d-none d-lg-block">
            <div style={{ height: "100px"}} className="d-none d-lg-block"/> 
            <p className="d-block d-lg-none" style={{ padding: 0, margin: 0, paddingTop: "50px"}}>Scroll to the right >></p>
            <div style={{ overflowX: "scroll"}}>
                <img 
                    src={require("../assets/img/browserBar.png")} alt="browser window"
                    className="img"
                    style={{ width: "600px"}}
                /> 
                <img 
                    src={require("../assets/img/analytics_dashboard.webp")} alt="browser window"
                    className="img"
                    style={{ width: "600px"}}
                /> 

            </div>
            
                
        </Col>
        
        <Col lg={4} style={{ padding: "20px"}}>
            <p className="heroSubtitle" style={{ margin: 0, marginTop: "20px", fontSize: "18px", fontWeight: "900", textAlign: "left"}}>
                Data-Driven Visibility
            </p>
            <p style={{ paddingTop: "10px", textAlign: "left"}}>
                With ceevie, you can track how many people have opened and viewed your Video CVs. This data-driven visibility lets you gauge the level of interest in your profile and see which employers are actively engaging with your application.
            </p>

            <p className="heroSubtitle" style={{ margin: 0, marginTop: "20px", fontSize: "18px", fontWeight: "900", textAlign: "left", paddingTop: "20px"}}>
                Content Performance Insights
            </p>
            <p style={{ paddingTop: "10px", textAlign: "left"}}>
                Delve into the analytics to see the number of views each section of your Video CV receives. This information allows you to identify which parts of your presentation are capturing the most attention, enabling you to fine-tune your content for maximum impact.
            </p>

            <p className="heroSubtitle" style={{ margin: 0, marginTop: "20px", fontSize: "18px", fontWeight: "900", textAlign: "left", paddingTop: "20px"}}>
                Strategic Interview Preparation
            </p>
            <p style={{ paddingTop: "10px", textAlign: "left"}}>
            Armed with detailed analytics, you gain a competitive edge in your job search. Use the insights to prepare strategically for interviews. Anticipate questions and discussion points related to the strengths and experiences that employers find most engaging in your Video CV.
            </p>
                
        </Col>
       

        </Row>
       
        
    </Container>
        
   </>
  );
}

export default Analytics;
