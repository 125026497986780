import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Sources() {

  return (
   <>
          <p className="heroSubtitle d-none d-lg-block" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px", width: "100%", textAlign: "center"}}>See what others are saying about Video CVs</p>
          <p className="heroSubtitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "34px", textAlign: "left", margin: 0, marginTop: "20px", width: "100%", paddingLeft: "24px", fontWeight: "900", paddingTop: "50px" }}>Don't just take our word for it</p>
          <p className="d-block d-lg-none" style={{ margin: 0, padding: 0, textAlign: "left", paddingLeft: "24px", marginTop: "10px", marginBottom: "20px"}}>See what others are saying about Video CVs</p>
          <Row className="pt-2 pb-4" style={{display: "flex"}}>
            <div style={{ flex: 2}} className="d-flex align-items-center justify-content-center">
              <img 
                src={require("../assets/img/bbc.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.bbc.com/worklife/article/20220121-the-jobseekers-boosting-their-applications-with-video-cvs", "_blank")}
              />
            </div>
            <div style={{ flex: 1}} className="d-flex align-items-center justify-content-center d-none d-lg-block">
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px" }}/>
            </div>
            <div style={{ flex: 2}} className="d-flex align-items-center justify-content-center">
              <img 
                 src={require("../assets/img/linkedin.png")} alt="downward arrow"
                 style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                 className="img pointer"
                 onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
               />
            </div>
            <div style={{ flex: 1}} className="d-flex align-items-center justify-content-center d-none d-lg-block">
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px"}}/>
            </div>
            <div style={{ flex: 2}} className="d-flex align-items-center justify-content-center">
              <img 
                src={require("../assets/img/guardian.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.theguardian.com/careers/careers-blog/how-to-make-video-cv", "_blank")}
              />
            </div>
            <div style={{ flex: 1}} className="d-flex align-items-center justify-content-center d-none d-lg-block">
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px"}}/>
            </div>
            <div style={{ flex: 2}} className="d-flex align-items-center justify-content-center">
              <img 
                src={require("../assets/img/prospects.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/create-a-great-video-cv", "_blank")}
              />
            </div>
            {/* <Col lg={2} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/bbc.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.bbc.com/worklife/article/20220121-the-jobseekers-boosting-their-applications-with-video-cvs", "_blank")}
              />
            </Col>
            <Col lg={1} md={6} className="d-flex align-items-center justify-content-center" >
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px"}}/>

            </Col>
            <Col lg={2} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/linkedin.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
              
              />
            </Col>
            <Col lg={2} md={6} className="d-flex align-items-center justify-content-center" >
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px"}}/>

            </Col>
            <Col lg={2} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/guardian.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.theguardian.com/careers/careers-blog/how-to-make-video-cv", "_blank")}
              
              />
            </Col>
            <Col lg={1} md={6} className="d-flex align-items-center justify-content-center" >
              <div style={{ height: "10px", width: "10px", backgroundColor: "grey", paddingRight: "-10px", borderRadius: "10px"}}/>

            </Col>
            <Col lg={2} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/prospects.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/create-a-great-video-cv", "_blank")}
              
              />
            </Col> */}
           
          </Row>
   </>
  );
}

export default Sources;
