import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Why() {

  return (
   <>
        <Container>
            <Row className="heroTitleContainer">
                <Col lg={6}>
                    <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", textAlign: "left", fontWeight: "800"}}>It's<span className="highlight" style={{ fontWeight: "800"}}>Your Story</span>, Not Credentials...</p>
                    <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "44px", textAlign: "left", fontWeight: "800"}}>It's<span className="highlight" style={{ fontWeight: "800"}}>Your Story</span>, Not Credentials...</p>


                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px"}}>
                        ... that will convince them. The first few seconds matter. Break free from written words, allow your authentic personality to shine. Forge genuine connections with potential employers right from the start.
                    </p>
                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px"}}>
                    Ready to transform the way you present yourself? Embrace the power of Video CVs and elevate your career journey today!
                    </p>
                    <div style={{ width: "100%", backgroundColor: "lightgrey", height: "1px", marginTop: "30px"}}/>
                    <p style={{ textAlign: "left", paddingTop: "30px", fontStyle: "italic", fontWeight: 600}}>"Ceevie helped me get interviews for my dream job. I got very positive feedback from a lot of people. I highly recommend it!"</p>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <div style={{ height: "60px", width: "60px", borderRadius: "100px", overflow: "hidden"}}>
                            <img 
                                src={require("../assets/img/testimonial.jpeg")} alt="browser window"
                                style={{ height: "60px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px"}}>
                            <p style={{ padding: 0, margin: 0, textAlign: "left", fontWeight: "900"}}>Alanna M.</p>
                            <p  style={{ padding: 0, margin: 0, textAlign: "left"}}>Event Manager</p>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <img 
                        src={require("../assets/img/iphone_1.png")} alt="browser window"
                        style={{ height: "500px", margin: "0 auto" }}
                        className="d-none d-lg-block"
                    />
                </Col>
                <div style={{ height: "50px", width: "100vw"}} className="d-block d-lg-none"/>
                
            </Row>
            <Row className="heroTitleContainer" style={{ paddingTop: "100px", paddingBottom: "0px"}}>
                <Col lg={6}>
                    <img 
                        src={require("../assets/img/iphone_2.png")} alt="browser window"
                        style={{ height: "500px", margin: "0 auto" }}
                        className="d-none d-lg-block"
                    />
                </Col>
                <Col lg={6}>
                    <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", textAlign: "left", fontWeight: "800"}}>Did You Know: <span className="highlight" style={{ fontWeight: "800"}}>89%</span> Of Employers...</p>
                    <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "44px", textAlign: "left", fontWeight: "800", marginTop: "-100px"}}>Did You Know: <span className="highlight" style={{ fontWeight: "800"}}>89%</span> Of Employers...</p>


                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px"}}>
                    ...are eager to view Video CVs? Seize this opportunity to redefine your self-presentation and elevate your career journey. 
                    </p>
                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px"}}>
                    Opt for a Video CV to make it easier for recruiters by offering a compelling, visual representation of your skills and personality, helping you stand out in the hiring process.
                    </p>
                    <div style={{ width: "100%", backgroundColor: "lightgrey", height: "1px", marginTop: "30px"}}/>
                    <p style={{ textAlign: "left", paddingTop: "30px", fontStyle: "italic", fontWeight: 600}}>"I often interview candidates for my team, it's time consuming and challenging evaluating them from CVs alone. A good first impression via a video CV is very helpful!"</p>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <div style={{ height: "60px", width: "60px", borderRadius: "100px", overflow: "hidden"}}>
                            <img 
                                src={require("../assets/img/testimonial_2.png")} alt="browser window"
                                style={{ height: "60px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px"}}>
                            <p style={{ padding: 0, margin: 0, textAlign: "left", fontWeight: "900"}}>Nick K.</p>
                            <p style={{ padding: 0, margin: 0, textAlign: "left"}}>Head of Technology</p>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="d-block d-lg-none">
                <div style={{ width: "100%", marginTop: "100px", overflowX: "scroll"}}>
                        <p>Scroll to the right >></p>
                        <div style={{  display: "flex", width: "700px",marginTop: "100px", marginBottom: "200px", margin: "0 auto"}}>
                            <div 
                                style={{ height: "100%", width: "50px", backgroundColor: "red", display: "block"}}
                                className="d-block d-lg-none"
                            >
                                <div style={{ width: "24px"}}/>
                            </div>
                            <div className="Container  fade_in" style={{ overflowY: "hidden"}}>
                                <img 
                                    src={require("../assets/img/demo.png")} alt="browser window"
                                    style={{ height: "400px" }}
                                />
                               
                            
                            </div>
                    </div>

                    </div>
                </Col>
               
            </Row>
        </Container>
   </>
  );
}

export default Why;
