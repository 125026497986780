import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import './index.css';
import App from './App';
import Blog from './Blog';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from './Privacy';
import TermsOfUse from './TermsOfUse';

ReactGA.initialize('G-99QF9PKMXW');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          
          <Route index element={<App />} /> 
          <Route path="blog" element={<Blog />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms-and-conditions" element={<TermsOfUse />} />
      </Routes>
    </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
