import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';



function Privacy() {

  return (
   <>
   <Container>

      <div style={{ maxWidth: "680px", margin: "0 auto", marginTop: "100px"}}>
        <p style={{ fontStyle: "italic"}}>Last Updated: 9th January 2024</p>
        <h1 style={{ paddingBottom: "20px"}}>Our Privacy Policy</h1>
        <p>This Privacy Policy outlines the methods and guidelines we adhere to regarding the collection, utilization, and disclosure of your information when you access our Service. It also informs you of your rights to privacy and the legal protections in place for you.</p>
        <p>We utilize your personal data to enhance and deliver the Service. Your use of the Service constitutes your consent to our collection and use of information as outlined in this Privacy Policy.</p>
        <h1 style={{ padding: "20px 0px"}}>Interpretation and Definitions</h1>
        <h2 style={{ padding: "20px 0px"}}>Interpretation</h2>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h2 style={{ padding: "20px 0px"}}>Definitions</h2>
        <p>For the purposes of this Privacy Policy:</p>
<ul>
    <li>
        <p><strong>Account</strong> signifies a distinct account established for You to access our Service on our platform.</p>
    </li>
    <li>
        <p><strong>Business</strong>, in the context of the CCPA (California Consumer Privacy Act), denotes the Company as the legal entity that gathers personal information of Consumers and decides the purposes and methods of processing Consumers' personal information, or for which such information is gathered. It alone, or in conjunction with others, decides the purposes and methods of processing consumers' personal information and operates within the State of California.</p>
    </li>
    <li>
        <p><strong>Company</strong> (also known as "the Company", "We", "Us", or "Our" in this Agreement) refers to ceevie.com website.<br />
        Under the GDPR, the Company acts as the Data Controller.</p>
    </li>
    <li>
        <p><strong>Consumer</strong>, as defined by the CCPA (California Consumer Privacy Act), is an individual who is a resident of California. A resident, as per the statute, includes (1) any person who is in the USA for purposes other than temporary or transitory, and (2) any person domiciled in the USA who is outside the USA for a temporary or transitory purpose.</p>
    </li>
    <li>
        <p><strong>Country</strong> refers to: Romania</p>
    </li>
    <li>
        <p><strong>Cookies</strong> are files placed on Your computer, mobile device, or any other device by a website, containing details of Your browsing history on that website among various uses.</p>
    </li>
    <li>
        <p><strong>Data Controller</strong>, for the GDPR (General Data Protection Regulation), is the Company as the legal entity that either solely or jointly with others decides on the purposes and means of processing Personal Data.</p>
    </li>
    <li>
        <p><strong>Device</strong> means any device that enables access to the Service, such as a computer, cellphone, or digital tablet.</p>
    </li>
    <li>
        <p><strong>Do Not Track</strong> (DNT) is an initiative advocated by US regulatory authorities, particularly the U.S. Federal Trade Commission (FTC), for the internet industry to develop a mechanism that allows internet users to manage the tracking of their online activities across websites.</p>
    </li>
    <li>
        <p>
            <strong>Personal Data</strong> is any information that can be linked to an identified or identifiable person.<br />
        Under the GDPR, Personal Data encompasses any information related to You such as a name, identification number, location data, online identifier, or factors specific to physical, physiological, genetic, mental, economic, cultural, or social aspects of your identity.<br/>
        For the CCPA, Personal Data is defined as any information that identifies, relates to, describes, is capable of being associated with, or could be reasonably linked, directly or indirectly, with You.</p>
    </li>
    <li>
        <p><strong>Sale</strong>, in the context of the CCPA (California Consumer Privacy Act), involves the act of selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating a Consumer's personal information to another business or third party, whether for monetary or other valuable consideration.</p>
    </li>
    <li>
        <p><strong>Service</strong> pertains to the Website.</p>
    </li>
    <li>
        <p><strong>Service Provider</strong> is any natural or legal entity that processes data on behalf of the Company. This includes third-party companies or individuals hired by the Company to facilitate the Service, to deliver the Service on the Company's behalf, to perform Service-related services, or to assist the Company in understanding how the Service is used.
        In terms of the GDPR, Service Providers are seen as Data Processors.</p>
    </li>
    <li>
        <p><strong>Usage Data</strong> is data collected automatically, either produced by the use of the Service or from the Service infrastructure itself (for example, the length of a page visit).</p>
    </li>
    <li>
        <p><strong>Website</strong> refers to ceevie

.com, accessible from <a href="https://www.ceevie.com" target="_blank">https://www.ceevie.com</a></p>
    </li>
    <li>
        <p><strong>You</strong> signifies the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.<br />
        In the context of GDPR (General Data Protection Regulation), You are recognized as the Data Subject or User, as you are the individual using the Service.</p>
    </li>
</ul>
<h1>Gathering and Utilizing Your Personal Data</h1>
<h2>Different Types of Data We Collect</h2>
<h3>Identifiable Personal Information</h3>
<p>As part of using Our Service, we might request you to provide specific personal information that can be used for contact or identification purposes. This identifiable personal information can consist of, but is not limited to:</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Phone number</p>
</li>
<li>
<p>Address, State, Province, ZIP/Postal code, City</p>
</li>
<li>
<p>Usage Data</p>
</li>
<li>
<p>Profile Data</p>
</li>
<li>
<p>Informtion pertaining to your job search</p>
</li>
</ul>
<h3>Information on Service Usage</h3>
<p>Information regarding your usage of the Service is automatically gathered.</p>
<p>This information may encompass details like your Device's Internet Protocol address (e.g., IP address), type of browser, browser version, the specific pages of our Service that you browse, the date and time of your visit, the duration spent on those pages, unique device identifiers, and other diagnostic data.</p>
<p>When accessing the Service with a mobile device, we automatically collect certain data, including, but not limited to, your mobile device's type, the unique ID of your mobile device, the IP address of your mobile device, your mobile operating system, the kind of mobile Internet browser you use, and other unique device identifiers and diagnostic data.</p>
<p>Additional information may be collected by your browser each time you visit our Service or when you access the Service using a mobile device.</p>
<h3>Usage of Tracking Technologies and Cookies</h3>
<p>We employ Cookies and similar tracking technologies to monitor activity on Our Service and retain certain types of information. These technologies, which include beacons, tags, and scripts, are utilized to gather and track information, and to enhance and analyze Our Service. The types of technologies we use may comprise:</p>
<ul>
<li><strong>Cookies or Browser Cookies.</strong> A small file known as a cookie is placed on Your Device. You have the option to set Your browser to reject all Cookies or to signal when a Cookie is being sent. However, if You choose not to accept Cookies, some aspects of our Service may become inaccessible. Our Service will use Cookies unless you have configured Your browser to reject them.</li>
<li><strong>Web Beacons.</strong> Certain areas of our Service and our emails might contain tiny electronic files known as web beacons (also called clear gifs, pixel tags, and single-pixel gifs) that allow ceevie, for instance, to count users who have viewed those pages or opened an email, and for other related website statistics (such as tracking the popularity of a specific section and confirming system and server integrity).</li>
</ul>
<p>Cookies can be either "Persistent" or "Session" based. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser.</p>
<p>We use both Session and Persistent Cookies for the reasons outlined below:</p>
<ul>
<li>
<p><strong>Necessary / Essential Cookies</strong></p>
<p>Type: Session Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies are critical to provide services available through the Website and to enable you to use some of its features. They help authenticate users and prevent fraudulent use of user accounts. Without these Cookies, we cannot deliver the services you request, and we use these Cookies solely for that purpose.</p>
</li>
<li>
<p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies determine if users have consented to the use of cookies on the Website.</p>
</li>
<li>
<p><strong>Functionality Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies enable us to remember your selections when you use the Website, like your login details or language preference. Their aim is to offer a more personalized experience and prevent you from having to reset your preferences each time you use the Website.</p>
</li>
<li>
<p><strong>Tracking and Performance Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Third-Parties</p>
<p>Purpose: These Cookies track information about traffic to the Website and user interaction with the Website. The data gathered through these Cookies may directly or indirectly identify you as a unique visitor since it is typically linked to a pseudonymous identifier associated with the device used to access the Website. We might also use these Cookies to test new pages, features, or new functionality of the Website to gauge user reactions.</p>
</li>
</ul>
<p>For detailed information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
<h2>Utilization of Your Personal Data</h2>
<p>ceevie may use Personal Data for the following purposes:</p>
<ul>
<li>
<p><strong>To deliver and maintain our Service</strong>, including monitoring the usage of our Service.</p>
</li>
<li>
<p><strong>To manage Your Account:</strong> managing your registration as a user of the Service. The Personal Data you provide allows you access to various functionalities of the Service available to registered users.</p>
</li>
<li>
<p><strong>For contract execution:</strong> developing, complying with, and executing the purchase contract for the products, items, or services you have bought or any other contract with Us through the Service.</p>
</li>
<li>
<p><strong>To contact You:</strong> To reach you by email, phone calls, SMS, or other equivalent forms of electronic communication, such as mobile application push notifications about updates or informative communications related to the functionalities, products, or contracted services, including security updates, when necessary or reasonable for their implementation.</p>
</li>
<li>
<p><strong>To offer You information</strong> on news, special deals, and general details about other goods, services, and events we offer that are similar to those you have already purchased or inquired about unless you choose not to receive such information.</p>
</li>
<li>
<p><strong>To handle Your requests:</strong> To attend to and manage your requests to Us.</p>
</li>
<li>
<p><strong>For business transitions:</strong> We might use your information to assess or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the transferred assets.</p>
</li>
<li>
<p><strong>For other purposes</strong>: We may use your information for other reasons, such as data analysis, identifying usage trends, assessing the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and your experience.</p>
</li>
</ul>
<p>We may share your personal information in the following scenarios:</p>
<ul>
<li><strong>With Service Providers:</strong> We may share your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, and to contact you.</li>
<li><strong>For business transitions:</strong> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
<li><strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with Us.</li>
<li><strong>With business partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
<li><strong>With other users:</strong> when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
<li><strong>With Your consent</strong>: We may disclose your personal information for any other purpose with your consent.</li>
</ul>
<h2>Hosting</h2>
<h3>External hosting</h3>
<p>This Website is hosted by an external service provider (host). The Personal Data collected on this Website is stored on the host’s servers. This may include IP addresses, contact requests, meta and communication data, contract data, contact data, names, website accesses and other data generated via a Website. The host is used for the purpose of fulfilling contracts with our potential and existing customers (Art. 6 para. 1 lit. b GDPR) and in the interest of a secure, fast and efficient provision of our online services by a professional provider (Art. 6 para. 1 lit. f GDPR). Our host will only process Your Data to the extent necessary to fulfill its service obligations and will follow our instructions regarding this Data. We use the following host: </p>
<ul>
    <li>
        <p>Firebase</p>
    </li>
</ul>
<h2>Storing Your Personal Data</h2>
<p>ceevie will keep your Personal Data only as long as necessary for the objectives stated in this Privacy Policy. Your Personal Data will be retained and used to the degree required to fulfill our legal duties (for instance, if retaining your data is mandated by law), settle disputes, and enforce our legal agreements and policies.</p>
<p>ceevie will also keep Usage Data for internal analysis. Generally, Usage Data is kept for a shorter duration, unless this data is needed to enhance security or functionality of Our Service, or we are legally bound to keep this data for longer periods.</p>
<h2>Transferring Your Personal Data</h2>
<p>Your data, including Personal Data, is processed at ceevie's operating locations and in any other places where the involved parties in the processing are located. This means your information may be transferred to — and kept on — computers situated outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.</p>
<p>Your agreement to this Privacy Policy followed by your submission of such information signifies your acceptance of that transfer.</p>
<p>ceevie will take all reasonably necessary measures to ensure your data is securely handled in accordance with this Privacy Policy and no transfer of Your Personal Data will occur to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
<h2>Revealing Your Personal Data</h2>
<h3>Business Transactions</h3>
<p>If ceevie is involved in a merger, acquisition, or asset sale, your Personal Data might be transferred. We will provide notice prior to your Personal Data being transferred and becoming subject to a different Privacy Policy.</p>
<h3>Law Enforcement</h3>
<p>Under certain circumstances, ceevie may be required to disclose your Personal Data if required by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>
<h3>Other Legal Obligations</h3>
<p>ceevie may reveal your Personal Data in the belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal requirement</li>
<li>Defend and protect the rights or property of ceevie</li>
<li>Prevent or investigate possible wrongdoing connected with the Service</li>
<li>Guard the personal safety of users of the Service or the public</li>
<li>Defend against legal liability</li>
</ul>
<h2>Securing Your Personal Data</h2>
<p>Your Personal Data’s security is important to us, but remember that no internet transmission method or electronic storage method is 100% secure. While we aim to use commercially acceptable means to protect your Personal Data, we cannot assure its absolute security.</p>
<h1>Comprehensive Information on the Handling of Your Personal Data</h1>
<p>The Service Providers we engage may access your Personal Data. These third-party entities collect, store, use, process, and transfer information about your activity on our Service in line with their Privacy Policies.</p>
<h2>Analytics</h2>
<p>We might employ third-party Service providers to monitor and analyze the usage of our Service.</p>
<ul>
<li>
<p><strong>Google Analytics</strong></p>
<p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to monitor and manage the use of our Service. This data is shared with other Google services. Google might use the collected data to contextualize and personalize the ads of its own advertising network.</p>
<p>You can prevent your activity on the Service from being available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visit activity.</p>
<p>For more details on Google’s privacy practices, kindly visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</a></p>
</li>
</ul>
<h2>Email Marketing</h2>
<p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that might interest you. You can opt out of receiving any or all of these communications from us by following the unsubscribe link or instructions in any email we send or by contacting us directly.</p>
<h2>Payments</h2>
<p>We may offer paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g., payment processors).</p>
<p>Your payment card details are not stored or collected by us but are provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
<ul>
<li>
<p><strong>Stripe</strong></p>
<p>Their Privacy Policy can be viewed at <a href="https://stripe.com/en-gb-us/privacy" rel="external nofollow noopener" target="_blank">https://stripe.com/en-gb-us/privacy</a></p>
</li>
{/* <li>
<p><strong>Braintree</strong></p>
<p>Their Privacy Policy can be viewed at <a href="https://www.braintreepayments.com/legal/braintree-privacy-policy" rel="external nofollow noopener" target="_blank">https://www.braintreepayments.com/legal/braintree-privacy-policy</a></p>
</li>
<li>
<p><strong>FastSpring</strong></p>
<p>Their Privacy Policy can be viewed at <a href="http://fastspring.com/privacy/" rel="external nofollow noopener" target="_blank">http://fastspring.com/privacy/</a></p>
</li> */}
</ul>
{/* <h2>Usage, Performance, and Miscellaneous</h2>
<p>We may utilize third-party Service Providers to improve our Service’s performance.</p>
<ul>
<li>
<p><strong>Awin and ShareASale</strong></p>
<p>Their Privacy Policy can be viewed at <a href="https://www.awin.com/us/privacy#how-we-use-data-from-advertisers-and-publishers" rel="external nofollow noopener" target="_blank">https://www.awin.com/us/privacy#how-we-use-data-from-advertisers-and-publishers</a></p>
</li>
</ul> */}
<h1>GDPR Privacy</h1>
<h2>Legal Basis for Processing Personal Data under GDPR</h2>
<p>We may process Personal Data under these conditions:</p>
<ul>
<li><strong>Consent:</strong> You have given consent for processing Personal Data for one or more specific purposes.</li>
<li><strong>Performance of a contract:</strong> The provision of Personal Data is necessary for the performance of an agreement with you and/or any pre-contractual obligations.</li>
<li><strong>Legal obligations:</strong> Processing is necessary for compliance with a legal obligation to which ceevie is subject.</li>
<li><strong>Vital interests:</strong> Processing is necessary to protect your vital interests or those of another natural person.</li>
<li><strong>Public interests:</strong> Processing is related to a task carried out in the public interest or in the exercise of official authority vested in ceevie.</li>
<li><strong>Legitimate interests:</strong> Processing is necessary for the legitimate interests pursued by ceevie.</li>
</ul>
<p>ceevie will gladly clarify the specific legal basis that applies to the processing, especially whether providing Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
<h2>Your Rights under the GDPR</h2>
<p>ceevie respects the confidentiality of your Personal Data and ensures you can exercise your rights.</p>
<p>You have the right under this Privacy Policy, and by law if within the EU, to:</p>
<ul>
<li><strong>Request access to Your Personal Data.</strong> The right to access, update, or delete the information we have on you. You can access, update, or request deletion of your Personal Data directly within your account settings section. If unable to perform these actions yourself, contact us for assistance. This also enables you to receive a copy of the Personal Data we hold about you.</li>
<li><strong>Request correction of the Personal Data we hold about you.</strong> You have the right to correct any incomplete or inaccurate information we hold about you.</li>
<li><strong>Object to processing of Your Personal Data.</strong> This right exists where we rely on a legitimate interest as the legal basis for our processing and there is something about your situation that makes you want to object to our processing on this ground. You also have the right to object to our processing of your Personal Data for direct marketing purposes.</li>
<li><strong>Request erasure of Your Personal Data.</strong> You have the right to ask us to delete or remove Personal Data when there is no valid reason for us to continue processing it.</li>
<li><strong>Initiate the transfer of Your Personal Data.</strong> We will provide You, or a third-party of Your choice, with Your Personal Data in a structured, widely-used, machine-readable format. Note that this right is applicable only to automated data which You originally consented for Us to use, or data used in the execution of a contract with You.</li>
<li><strong>Revoke Your consent.</strong> You hold the right to revoke Your consent regarding the use of your Personal Data. Should You revoke Your consent, We might be unable to grant You access to certain specific features of the Service.</li>
</ul>
<h2>How to Exercise Your GDPR Data Protection Rights</h2>
<p>To exercise Your rights of access, correction, erasure, and objection, please contact Us. Be aware that we may request You to verify Your identity before we can act on such requests. If You submit a request, We will endeavor to respond to You as promptly as possible.</p>
<p>If You believe there is an issue with how We collect and use Your Personal Data, You have the right to lodge a complaint with a Data Protection Authority. For more details, if located in the European Economic Area (EEA), kindly reach out to Your local data protection authority in the EEA.</p>
{/* <h1>Facebook Fan Page</h1>
<h2>Data Management for the Facebook Fan Page</h2>
<p>The Company is responsible for managing Your Personal Data collected during the use of the Service. As the operator of the Facebook Fan Page <a href="https://www.facebook.com/ceevie" rel="external nofollow noopener" target="_blank">https://www.facebook.com/ceevie</a>, the Company along with Facebook's network operator, serve as Joint Controllers.</p>
<p>The Company has agreed upon terms with Facebook, which outline the usage of the Facebook Fan Page, among other aspects. These terms largely rely on the Facebook Terms of Service: <a href="https://www.facebook.com/terms.php" rel="external nofollow noopener" target="_blank">https://www.facebook.com/terms.php</a></p>
<p>For detailed information on Facebook's privacy practices, visit the Facebook Privacy Policy here: <a href="https://www.facebook.com/policy.php" rel="external nofollow noopener" target="_blank">https://www.facebook.com/policy.php</a> or contact Facebook directly online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.</p>
<h2>Facebook Insights</h2>
<p>We utilize the Facebook Insights feature as part of managing the Facebook Fan Page, based on GDPR, to gather anonymous statistical information about Our users.</p>
<p>For this purpose, Facebook installs a Cookie on the user's device when they visit Our Facebook Fan Page. Each Cookie contains a distinct identifier code and is active for two years, unless deleted earlier.</p>
<p>Facebook collects, records, and processes the data stored in the Cookie, particularly when the user accesses Facebook services, services offered by other Facebook Fan Page members, and services from companies that use Facebook services.</p>
<p>For further information regarding Facebook's privacy practices, please see the Facebook Privacy Policy here: <a href="https://www.facebook.com/privacy/explanation" rel="external nofollow noopener" target="_blank">https://www.facebook.com/privacy/explanation</a></p> */}
<h1>CCPA Privacy</h1>
<p>This section of the privacy notice, aimed at California residents, supplements the information in Our Privacy Policy and applies exclusively to visitors, users, and others residing in the State of California.</p>
<h2>Types of Personal Information Collected</h2>
<p>We accumulate information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a specific Consumer or Device. Below is a list of types of personal information which we might collect or may have collected from California residents in the last twelve (12) months.</p>
<p>Note that the categories and examples provided below are as defined in the CCPA. This does not imply that all examples of such category of personal information were indeed collected by Us, but reflects our good faith belief to the best of our knowledge that some information from the said category may be and may have been collected. For instance, certain types of personal information would be collected only if You provide it to Us directly.</p>
<ul>
<li>
<p><strong>Category A: Identifiers.</strong></p>
<p>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.</p>
<p>Collected: Yes.</p>
</li>
<li>
<p><strong>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</strong></p>
<p>Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</p>
<p>Collected: Yes.</p>
</li>
<li>
<p><strong>Category C: Classified characteristics under California or federal law.</strong></p>
<p>Examples include: Age (over 40 years), ethnicity, hue, lineage, national identity, citizenship status, belief system, marital condition, health status, physical or mental impairments, gender (including identity, expression, pregnancy, or childbirth and associated medical conditions), sexual identity, service in the military, genetic details (including family genetic information).</p>
<p>Collected: No.</p>
</li>
<li>
<p><strong>Category D: Business-related information.</strong></p>
<p>Examples include: Documentation and chronology of products or services bought or considered.</p>
<p>Collected: Yes.</p>
</li>
<li>
<p><strong>Category E: Biometric data.</strong></p>
<p>Examples include: Genetic, physiological, behavioral, and biological attributes, or patterns utilized for extracting a template or other identifier or identifying details, such as fingerprints, facial patterns, and vocal prints, eye or retina scans, keystroke dynamics, walking styles, or other physical patterns, and data related to sleep, health, or physical activity.</p>
<p>Collected: No.</p>
</li>
<li>
<p><strong>Category F: Online or similar network activities.</strong></p>
<p>Examples include: Engagement with our Service or advertisements.</p>
<p>Collected: Yes.</p>
</li>
<li>
<p><strong>Category G: Geographical data.</strong></p>
<p>Examples include: Rough physical whereabouts.</p>
<p>Collected: Yes.</p>
</li>
<li>
<p><strong>Category H: Sensory information.</strong></p>
<p>Examples include: Auditory, electronic, visual, thermal, olfactory, or comparable data.</p>
<p>Collected: No.</p>
</li>
<li>
<p><strong>Category I: Professional or employment-oriented data.</strong></p>
<p>Examples include: Current or prior job records or performance reviews.</p>
<p>Collected: No.</p>
</li>
<li>
<p><strong>Category J: Non-public educational details (as per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</strong></p>
<p>Examples include: Educational records directly associated with a student managed by an educational institution or its representative, such as grades, transcripts, class lists, student schedules, student identification codes, student financial details, or student disciplinary records.</p>
<p>Collected: No.</p>
</li>
<li>
<p><strong>Category K: Deductions made from other personal details.</strong></p>
<p>Examples include: A profile reflecting a person’s preferences, characteristics, psychological tendencies, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
<p>Collected: No.</p>
</li>
</ul>
<p>Under CCPA, personal information does not encompass:</p>
<ul>
<li>Public information available from government records</li>
<li>Deidentified or aggregated user information</li>
<li>Information outside the CCPA's purview, such as:
<ul>
<li>Health or medical data governed by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial information</li>
<li>Data covered by specific sector-related privacy legislations, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994</li>
</ul>
</li>
</ul>
<h2>Sources of Personal Information</h2>
<p>We collect the types of personal information mentioned above from the sources listed below:</p>
<ul>
<li><strong>Directly from You</strong>. This includes information that you provide in forms on our Service, your stated preferences, or from your transactions on our Service.</li>
<li><strong>Indirectly from You</strong>. This includes information gathered by observing your interactions on our Service.</li>
<li><strong>Automatically from You</strong>. This includes information collected through cookies set by us or our Service Providers on your Device during your navigation of our Service.</li>
<li><strong>From Service Providers</strong>. This includes information obtained from third-party vendors who help monitor and analyze our Service usage, process payments, or contribute to the provision of our Service to you.</li>
</ul>
<h2>Use of Personal Information for Business or Commercial Purposes</h2>
<p>We utilize or share personal information collected for "business purposes" or "commercial purposes" (as defined under the CCPA), which might include uses like:</p>
<ul>
<li>Managing our Service and delivering it to you.</li>
<li>Offering support and responding to your requests, including addressing concerns and enhancing our Service.</li>
<li>Fulfilling the purpose for which you provided the information. For instance, using your contact details to respond to queries about our Service, or using your payment information to process transactions.</li>
<li>Complying with requests from law enforcement and legal obligations.</li>
<li>As previously explained when your personal information was collected or as otherwise stated in the CCPA.</li>
<li>For internal purposes like administration and auditing.</li>
<li>Identifying and addressing security incidents, fraud, and illegal activity.</li>
</ul>
<p>Note that the above examples are illustrative and not exhaustive. For more comprehensive information on how we utilize this data, refer to the "Use of Your Personal Data" section.</p>
<p>If we plan to collect additional personal information categories or use the collected data for significantly different, unrelated, or incompatible purposes, we will update this Privacy Policy.</p>
<h2>Disclosure of Personal Information for Business or Commercial Purposes</h2>
<p>Over the past twelve (12) months, we may have used or shared the following categories of personal information for business or commercial reasons:</p>
<ul>
<li>Category A: Identifiers</li>
<li>Category B: Personal information categories as per the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
<li>Category D: Commercial information</li>
<li>Category F: Internet or similar network activity</li>
</ul>
<p>Note that these categories are as defined in the CCPA. This does not imply that all personal information in these categories was disclosed, but rather indicates our belief that some information from these categories may have been shared.</p>
<p>In cases where we share personal information for business or commercial purposes, we engage in contracts that outline the purpose and mandate confidentiality and restricted use of the information in line with the contract’s terms.</p>
<h2>Sale of Personal Information</h2>
<p>As per the CCPA's definition, "sell" and "sale" involve transferring or making available a consumer's personal information to a third party in exchange for value, not necessarily monetary. This indicates that we may have benefited from sharing personal information, though not necessarily financially.</p>
<p>Note that the categories listed below are CCPA-defined. This does not guarantee that all personal information in these categories was sold, but reflects our belief based on our knowledge that some of this information may have been exchanged for value.</p>
<p>In the past twelve (12) months, we may have sold the following personal information categories:</p>
<ul>
<li>Category A: Identifiers</li>
<li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
<li>Category D: Commercial information</li>
<li>Category F: Internet or other similar network activity</li>
</ul>
<h2>Sharing of Personal Information</h2>
<p>We may share your personal information as identified in the above categories with these types of third parties:</p>
<ul>
<li>Service Providers</li>
<li>Payment processors</li>
<li>Our affiliates</li>
<li>Our business partners</li>
<li>Third party vendors authorized by you or your agents for products or services we provide to you</li>
</ul>
<h2>Sale of Personal Information of Minors Under 16 Years of Age</h2>
<p>We do not intentionally collect personal information from minors under 16 via our Service. However, certain third-party sites we link to may do so. These sites have their own terms and privacy policies, and we urge parents and guardians to monitor their children's internet use and guide them in safe online behavior.</p>
<p>We do not sell personal information of consumers under 16 years of age unless we have explicit consent (the "right to opt-in") from consumers between 13 and 16 years old, or from a parent or guardian for consumers under 13. Consumers can opt-out of future sales anytime. To exercise this right, contact us directly.</p>
<p>If you suspect that a child under 13 (or 16) has provided us with personal information, please reach out to us with enough details to help us remove that information.</p>
<h2>Your Rights under the CCPA</h2>
<p>If you are a California resident, the CCPA grants you specific rights regarding your personal information, including:</p>
<ul>
<li><strong>The right to be informed.</strong> You can receive notice about the categories of Personal Data being collected and their intended uses.</li>
<li><strong>The right to make a request.</strong> Under the CCPA, you can ask us to disclose details about our collection, use, sale, disclosure for business reasons, and sharing of personal information. Upon verifying your request, we will provide information on:
<ul>
<li>The types of personal information we have gathered about you</li>
<li>The sources of the personal information we collected</li>
<li>Our business or commercial reasons for collecting or selling that information</li>
<li>The categories of third parties with whom we share that information</li>
<li>The specific personal information we have collected about you</li>
<li>If we have sold or disclosed your personal information for business purposes, we will inform you about:
<ul>
<li>The categories of personal information sold</li>
<li>The categories of personal information disclosed</li>
</ul>
</li>
</ul>
</li>
<li><strong>The right to refuse the sale of Personal Data (opt-out).</strong> You can instruct us not to sell your personal information. Contact us to submit an opt-out request.</li>
<li><strong>The right to delete Personal Data.</strong> You can ask us to erase your Personal Data, with some exceptions. After verifying your request, we will delete your information (and instruct our Service Providers to do the same) unless an exception applies. We may deny your deletion request if retaining the information is necessary for us or our Service Providers to:
<ul>
<li>Complete transactions, provide goods or services you requested, or fulfill our contract with you.</li>
<li>Detect and address security issues, fraud, or illegal activities.</li>
<li>Identify and fix errors that impair existing functionality.</li>
<li>Exercise free speech, or ensure others' free speech rights, or exercise another right provided by law.</li>
<li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
<li>Conduct public or peer-reviewed research in line with all other applicable ethics and privacy laws, when deletion would impair the research, and you have previously consented.</li>
<li>Facilitate uses aligned with your expectations based on your relationship with us.</li>
<li>Comply with legal obligations.</li>
<li>Make lawful internal uses of the information consistent with the context in which you provided it.</li>
</ul>
</li>
<li><strong>The right not to be discriminated against.</strong> You have the right to equal service and price, regardless of whether you exercise your consumer rights, including:
<ul>
<li>Not being denied goods or services</li>
<li>Not being charged different prices or rates for goods or services, including through the use of discounts, benefits, or penalties</li>
<li>Not receiving a different level or quality of goods or services</li>
<li>Not being suggested that you may receive a different price or rate for goods or services or a different level or quality of goods or services</li>
</ul>
</li>
</ul>
<h2>Exercising Your CCPA Data Protection Rights</h2>
<p>To exercise any rights under the CCPA, if you are a resident of California, you can reach out to us:</p>
<ul>
<li>By accessing this page on our website: <a href="https://www.ceevie.com/contact/" target="_blank">https://www.ceevie.com/contact/</a></li>
</ul>
<p>Only you, or an individual registered with the California Secretary of State authorized to act on your behalf, may submit a verifiable request regarding your personal data.</p>
<p>Your request to us should:</p>
<ul>
<li>Include enough information that enables us to reasonably confirm your identity or that of your authorized representative</li>
<li>Detail your request adequately so we can understand, evaluate, and address it properly</li>
</ul>
<p>We cannot fulfill your request or provide the necessary information if we are unable to:</p>
<ul>
<li>Confirm your identity or your authority to make the request</li>
<li>Verify that the personal data pertains to you</li>
</ul>
<p>We commit to disclosing and delivering the required information free of charge within 45 days of receiving your verifiable request. We may extend this period by an additional 45 days when reasonably necessary, with prior notice.</p>
<p>Any disclosures we provide will encompass only the 12-month period preceding the receipt of the verifiable request.</p>
<p>For data portability requests, we will provide your personal information in a format that is easily usable and allows you to transmit the information from one entity to another without hindrance.</p>
<h2>Do Not Sell My Personal Information</h2>
<p>You have the right to opt-out of the sale of your personal information. Upon receiving and confirming a verifiable consumer request from you, we will cease selling your personal information. To exercise your right to opt-out, please reach out to us.</p>
<p>Our Service Providers, such as analytics or advertising partners, might use technology on the Service that constitutes selling personal information as defined by CCPA law. If you wish to opt out from the use of your personal information for interest-based advertising and potential sales as outlined under CCPA law, please follow the instructions below.</p>
<p>Note that opting out is browser-specific. You may need to opt out on each browser that you use.</p>
<h3>Website</h3>
<p>To opt out of personalized ads served by our Service Providers, follow our guidelines on the Service:</p>
<ul>
<li>The NAI's opt-out platform: <a href="http://www.networkadvertising.org/choices/" rel="external nofollow noopener" target="_blank">http://www.networkadvertising.org/choices/</a></li>
<li>The EDAA's opt-out platform <a href="http://www.youronlinechoices.com/" rel="external nofollow noopener" target="_blank">http://www.youronlinechoices.com/</a></li>
<li>The DAA's opt-out platform: <a href="http://optout.aboutads.info/?c=2&amp;lang=EN" rel="external nofollow noopener" target="_blank">http://optout.aboutads.info/?c=2&amp;lang=EN</a></li>
</ul>
<p>The opt out will implement a cookie on your computer that is unique to the browser you use to opt out. If you switch browsers or delete your browser's cookies, you will need to opt out again.</p>
<h3>Mobile Devices</h3>
<p>Your mobile device may offer you the option to opt out of the use of information about the apps you use for targeted advertising:</p>
<ul>
<li>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</li>
<li>"Limit Ad Tracking" on iOS devices</li>
</ul>
<p>Additionally, you can prevent the collection of location information from your mobile device by modifying your device's preferences.</p>
<h1>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</h1>
<p>Our Service does not adhere to Do Not Track signals.</p>
<p>However, some third-party websites do track your browsing activities. If you are visiting such websites, you can set your web browser to signal to websites that you do not wish to be tracked. You can activate or deactivate DNT by adjusting the preferences or settings of your web browser.</p>
<h1>Children's Privacy</h1>
<p>Our Service is not aimed at individuals under the age of 13. We do not intentionally gather personally identifiable information from anyone under 13. If you are a parent or guardian and become aware that your child has provided us with Personal Data, please contact us. If we learn that we have collected Personal Data from anyone under 13 without parental consent verification, we will take steps to eliminate that information from our servers.</p>
<p>If we need consent as a legal basis for processing your data and your country mandates parental consent, we may seek your parent's consent before using that information.</p>
<h1>Your California Privacy Rights (California's Shine the Light law)</h1>
<p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents who have an established business relationship with us can request information once a year about sharing their Personal Data with third parties for those parties' direct marketing purposes.</p>
<p>If you are a California resident and wish to request more information under the California Shine the Light law, you can contact us using the information below.</p>
<h1>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h1>
<p>California Business and Professions Code section 22581 permits California residents under 18 who are registered users of online sites, services, or applications to request and obtain removal of content or information they have publicly posted.</p>
<p>If you are a California resident and want to request such data removal, contact us using the information provided below, and include your account's associated email address.</p>
<p>Be aware that your request does not ensure complete or comprehensive removal of online content or information, and the law may not permit or require removal in certain instances.</p>
<h1>Links to Other Websites</h1>
<p>Our Service may include links to external websites not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly encourage you to review the Privacy Policy of every site you visit.</p>
<p>We do not control, and are not responsible for, the content, privacy policies, or practices of any third-party sites or services.</p>
<h1>Changes to this Privacy Policy</h1>
<p>We may periodically update our Privacy Policy. We will inform you of any changes by posting the new Privacy Policy on this page.</p>
<p>We will notify you via email and/or a prominent notice on our Service before the change becomes effective and update the "Last updated" date at the top of this Privacy Policy.</p>
<p>We advise you to review this Privacy Policy regularly for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<h1>Contact Us</h1>
<p>If you have any inquiries about this Privacy Policy, you can reach out to us:</p>
<ul>
<li>E-Mail: ceeviebusiness@gmail.com</li>
</ul>



     
      </div>
      
    </Container>
   
       
   </>
  );
}

export default Privacy;
