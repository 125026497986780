import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hero() {

  return (
   <>
   <Container>
      <Row className="heroTitleContainer">
        <Col>
            {/* <p className="heroTitle">Be Seen. <span className="highlight">Video CVs</span></p> */}
            <p className="heroTitle fade_in d-none d-lg-block" style={{ fontSize: "84px"}}>Elevate <span style={{ padding: "10px -10px 10px -10px", margin: "0px 10px 0px 0px"}} className="highlight">your</span>Story</p>
            <p className="heroTitle fade_in d-block d-lg-none" style={{ fontSize: "44px", lineHeight: "54px", textAlign: "left"}}>Stand out with a <span style={{ padding: "10px -10px 10px -10px", margin: "0px 10px 0px 0px"}} className="highlight">Video CV</span></p>

            <div className="heroSubtitleContainer fade_in d-none d-lg-block" style={{ animationDelay: "1s"}}>
                <p 
                    style={{ margin: "0 auto" , padding: 0, marginTop: "20px", maxWidth: "600px"}}
                >
                        Rise above the noise 🚀: showcase your skills and personality with our Video CV platform 🎥. Create, share, and track, all in one place📍
                </p>
            </div>
            <div className="fade_in d-block d-lg-none" style={{ animationDelay: "1s", margin: 0, padding: 0}}>

                <p 
                    style={{ margin: 0, padding: 0, marginTop: "20px", textAlign: "left", fontSize: "18px"}}
                >
                        Rise above the noise 🚀: showcase your skills and personality with our Video CV platform 🎥. Create, share, and track, all in one place📍
                </p>

            </div>
            <img 
                src={require("../assets/img/001-bulb.png")} alt="downward arrow"
                style={{ width: "64px", filter: "grayscale(100%);%", position: "absolute", left: "150px", top: "200px", transform: "rotate(-30deg)"}}
                className="img pointer d-none d-lg-block    "
            />
             <img 
                src={require("../assets/img/002-star.png")} alt="downward arrow"
                style={{ width: "44px", filter: "grayscale(100%);%", position: "absolute", left: "100px", top: "500px", transform: "rotate(-30deg)"}}
                className="img pointer d-none d-lg-block"
            />
            <img 
                src={require("../assets/img/001-film-making.png")} alt="downward arrow"
                style={{ width: "64px", filter: "grayscale(100%);%", position: "absolute", right: "100px", top: "350px", transform: "rotate(20deg)"}}
                className="img pointer d-none d-lg-block"
            />
        </Col>
      </Row>
      <Row className="heroTitleContainer fade_in d-none d-lg-block" style={{ margin: 0, padding: 0, animationDelay: "1s"}}>
        <Col lg={8}>
            <div style={{ display: "flex", flexDirection: "row", width: "210px", margin: "0 auto"}} className="shake">
                <div style={{  display: "flex", flexDirection: "row", backgroundColor: "#D5EC8B", width: "210px", borderRadius: "100px", margin: "0 auto"}}>
                    <div style={{ width: "40px", height: "40px", backgroundColor: "white", paddingTop: "6px", paddingLeft: "3px", borderRadius: "50px", margin: "5px 5px 5px 5px" }}>
                        <img 
                            src={require("../assets/img/001-right-arrow.png")} alt="downward arrow"
                            style={{ width: "20px", filter: "grayscale(100%)"}}
                            className="img pointer"
                        />
                    </div>
                    <p style={{margin: "0 auto", height: "50px", paddingTop: "10px", fontWeight: "700", fontSize: "20px", color: "black", marginLeft: 0,}}><a style={{ textDecoration: "none", color: "black"}} href="https://account.ceevie.com/#/sign-up">Start Free Trial!</a></p>
                </div>
            </div> 
        </Col>
      </Row>
      <Row className="heroTitleContainer fade_in d-block d-lg-none" style={{ margin: 0, padding: 0, animationDelay: "1s"}}>
        <Col lg={8}>
            <div style={{ display: "flex", flexDirection: "row", width: "210px", paddingTop: "30px"}} className="shake">
                <div style={{  display: "flex", flexDirection: "row", backgroundColor: "#D5EC8B", width: "210px", borderRadius: "100px", margin: "0 auto"}}>
                    <div style={{ width: "40px", height: "40px", backgroundColor: "white", paddingTop: "6px", paddingLeft: "3px", borderRadius: "50px", margin: "5px 5px 5px 5px" }}>
                        <img 
                            src={require("../assets/img/001-right-arrow.png")} alt="downward arrow"
                            style={{ width: "20px", filter: "grayscale(100%)"}}
                            className="img pointer"
                        />
                    </div>
                    <p style={{margin: "0 auto", height: "50px", paddingTop: "10px", fontWeight: "700", fontSize: "20px", color: "black", marginLeft: 0,}}><a style={{ textDecoration: "none", color: "black"}} href="https://account.ceevie.com/#/sign-up">Start Free Trial!</a></p>
                </div>
            </div> 
        </Col>
      </Row>
      
    </Container>
   {/* <div style={{ height: "60vh", width: "100vw", backgroundColor: "white" }}>
        <div>
            <p className="heroTitle">Be Seen. Video CVs for professionals</p>
        </div>
        <span style={{ backgroundColor: "#FFA17A", padding: "10px", borderRadius: "20px"}}>Be Seen.</span>
       
       
        <p>Stand out from the crowd and show off your skills and personality with an innovative video CV. Create, share and track all within one platform.</p>
    </div> */}
        {/* <section>
            <Container>
                <Row>
                    <Col lg="6" sm="12">
                        <div >
                            1
                        </div>
                    </Col>
                    <Col lg="6" sm="12">
                        <div >
                            2
                        </div>
                    </Col>
                </Row>
            </Container>

        </section> */}
       
   </>
  );
}

export default Hero;
