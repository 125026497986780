import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import ReactGA from 'react-ga4';

import './App.css';
import Screen from "./components/Screen";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import Sources from './components/Sources';
import Why from './components/Why';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import NotReplacement from './components/NotReplacement';
import Analytics from './components/Analytics';
import Features from './components/Features';
import CookieOverlay from './components/CookieOverlay';
import Pricing from './components/Pricing';
import Screen2 from './components/Screen2';

function App() {
  const [hasConsent, setHasConsent] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyBYpwXC1tDeN2TGz1iZ-U5xES31w1DA6Dg",
    authDomain: "ceevielandingpage-b616e.firebaseapp.com",
    projectId: "ceevielandingpage-b616e",
    storageBucket: "ceevielandingpage-b616e.appspot.com",
    messagingSenderId: "866035487550",
    appId: "1:866035487550:web:c92fff51425809ad23126f",
    measurementId: "G-3L6K80WSEZ"
  };

  useEffect(() => {
    // Check if consent is already given
    const consent = localStorage.getItem('analyticsConsentCeevie');
    if (consent === "true") {
      initializeApp(firebaseConfig);
      ReactGA.initialize('G-99QF9PKMXW');
    }
    setHasConsent(consent === "true");
  }, []);

  const handleConsent = (consentGiven) => {
    localStorage.setItem('analyticsConsentCeevie', consentGiven);
    setHasConsent(true);
    if (consentGiven) {
      console.log("Starting analytics...");
      initializeApp(firebaseConfig);
    }
  };

  return (
    <div className="App">
      {
        (!hasConsent) ? <CookieOverlay handleConsent={handleConsent}/> : null
      }
      <Navigation />
      <Hero />
      <Screen />
      <Screen2 />
      <Sources />
      <CallToAction />
      <Why />
      <NotReplacement />
      <Analytics />
      <Features />
      {/* <Sources /> */}
      
      
      <Pricing />
      <Footer />
      <p className="d-none d-lg-block" style={{ position: "fixed", bottom: "0px", right: "20px", color: "lightgrey", fontSize: "8px"}}>v1.0.1</p>
    </div>
  );
}

export default App;

