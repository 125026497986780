import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function NotReplacement() {

  return (
   <>
    <div style={{ backgroundColor: "#416C90", margin: 0, padding: 0, width: "100vw"}}>
        <Container>
            <Row className="heroTitleContainer" style={{ padding: "100px 0px"}}>
                <Col lg={6}>
                        <img 
                            src={require("../assets/img/not_replacement.jpg")} alt="browser window"
                            style={{ height: "500px", borderRadius: "10px" }}
                            className="d-none d-lg-block"
                        />
                        <img 
                            src={require("../assets/img/not_replacement.jpg")} alt="browser window"
                            style={{ height: "300px", borderRadius: "10px", margin: "-50px auto 30px" }}
                            className="d-block d-lg-none"
                        />
                    </Col>
                <Col lg={6}>
                    <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", textAlign: "left", fontWeight: "800", color: "white"}}>Don't Replace, Enhance!</p>
                    <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "34px", textAlign: "left", fontWeight: "800", color: "white"}}>Don't Replace, Enhance!</p>


                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px", color: "white"}}>
                    Combine traditional written CVs to showcase your qualifications and experience  with Video CVs to let your personality shine. Companies usually have established procedures to follow, but firms often allow you to link accounts and ask for cover letters.
                    </p>
                    <p className="heroSubtitle" style={{ textAlign: "left", margin: 0, marginTop: "20px", fontSize: "18px",  color: "white"}}>
                    Here, you can include a URL to your video CV, offering a unique chance to shine. Should a recruiter be intrigued by your profile and wish to learn more, you enable them to do so with a single click!
                    </p>
                    
                </Col>
               
            </Row>
            
        </Container>
    </div>
        
   </>
  );
}

export default NotReplacement;
