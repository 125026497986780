

import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Pricing() {

  return (
   <>
    <Container>
        <Row className="heroTitleContainer" style={{ padding: "0px 0px 20px 0px"}}>
            <Col lg={12}>
                <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "34px", fontWeight: "800", textAlign: "left"}}>Our Price</p>
                <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", fontWeight: "800"}}>Our Price</p>
                <p className="d-block d-lg-none" style={{ padding: 0, margin: 0, paddingTop: "20px", textAlign: "left"}}>Sign up now for a<span className="highlight">one-week free trial</span>and create engaging video resumes that make you stand out. Don't miss this chance to impress potential employers! </p>
                <p className="d-none d-lg-block"style={{ padding: 0, margin: 0, paddingTop: "40px"}}>Sign up now for a<span className="highlight">one-week free trial</span>and create engaging video resumes that make you stand out. Don't miss this chance to impress potential employers! </p>

            </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center pt-2 pb-5">
        <Col lg={12} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
            <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "30px", zIndex: "100", padding: "20px", border: "3px solid black"}}>
            <div style={{ display: "flex", flexDirection: "row"}} className="p-4">
                <div style={{ flexDirection: "column"}}>
                <p className="fontPoppins" style={{ fontWeight: "bold", color: "black", margin: "0px", color: "black", fontSize: "60px", lineHeight: "60px", width: "100%", textAlign: "left"}}>$3.25<span className="fontSmall fontPoppins" style={{ fontWeight: "300", fontSize: "30px"}}>/week</span></p>
                <p style={{  margin: "0px", width: "100%", textAlign: "left",paddingBottom: "10px" }}><span className="highlight" style={{ padding: "5px"}}>First week free!</span></p>              
                <div className="ml-4">
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>up to 10 videos</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>integrated analytics tool</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>1 GB of storage included</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>no tech skills required</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>unique url</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/green_check.png")} alt="storage"
                            style={{ height: "30px", filter: "grayscale(100%)"}}
                            className="img"
                        />
                        <p className="fontPoppins fontSmall" style={{ margin: 0, padding: 0, paddingLeft: "10px", paddingTop: "3px"}}>absolutely no ads or branding</p>
                    </div>
                    
                    
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "210px", margin: "0 auto", paddingTop: "20px"}} className="shakeAlways">
                    <div style={{  display: "flex", flexDirection: "row", backgroundColor: "#D5EC8B", width: "210px", borderRadius: "100px", margin: "0 auto"}}>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", paddingTop: "6px", paddingLeft: "3px", borderRadius: "50px", margin: "5px 5px 5px 5px" }}>
                            <img 
                                src={require("../assets/img/001-right-arrow.png")} alt="downward arrow"
                                style={{ width: "20px", filter: "grayscale(100%)"}}
                                className="img pointer"
                            />
                        </div>
                        <p style={{margin: "0 auto", height: "50px", paddingTop: "10px", fontWeight: "700", fontSize: "20px", color: "black", marginLeft: 0,}}><a style={{ color: "black", textDecoration: "none"}}href="https://account.ceevie.com/#/sign-up">Start Free Trial!</a></p>
                    </div>
                </div> 
                </div>
            </div>
            </div>
        </Col>

        </Row>
            {/* <img 
                src={require("../assets/img/001-bulb.png")} alt="downward arrow"
                style={{ width: "64px", filter: "grayscale(100%);%", position: "relative", left: "-350px", top: "-330px", transform: "rotate(-30deg)" }}
                className="img pointer"
            />
              <img 
                src={require("../assets/img/002-star.png")} alt="downward arrow"
                style={{ width: "44px", filter: "grayscale(100%);%", position: "relative", right: "-400px", top: "-450px", transform: "rotate(-30deg)"}}
                className="img pointer"
            />
            <img 
                src={require("../assets/img/001-film-making.png")} alt="downward arrow"
                style={{ width: "64px", filter: "grayscale(100%);%", position: "relative", right: "-300px", top: "-250px", transform: "rotate(20deg)"}}
                className="img pointer"
            /> */}
        </Container>
   </>
  )
}

export default Pricing;



