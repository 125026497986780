import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Features() {

  return (
   <>
    <Container>
        <Row className="heroTitleContainer" style={{ padding: "0px 0px 20px 0px"}}>
            <Col lg={12}>
                <p className="heroTitle d-none d-lg-block" style={{ fontSize: "48px", lineHeight: "54px", fontWeight: "800"}}>Our Top <span className="highlight">Features</span>!</p>
                <p className="heroTitle d-block d-lg-none" style={{ fontSize: "34px", lineHeight: "44px", fontWeight: "800", textAlign: "left",}}>Our Top <span className="highlight" style={{ margin: "0"}}>Features</span>!</p>

                <p className="d-none d-lg-block" style={{ padding: 0, margin: 0, paddingTop: "40px"}}>Explore the key features that make ceevie the ideal platform for showcasing your skills and experiences seamlessly!</p>
                <p className="d-block d-lg-none" style={{ padding: 0, margin: 0, paddingTop: "40px", textAlign: "left"}}>Explore the key features that make ceevie the ideal platform for showcasing your skills and experiences seamlessly!</p>

            </Col>
        </Row>
        <Row>
        <Col lg={4} style={{ padding: "20px"}} sm={12}>
            <div style={{padding: "20px" }} className="d-none d-lg-block">
                
                <img 
                    src={require("../assets/img/003-video-symbol-sketched-variant.png")} alt="browser window"
                
                    style={{ width: "50px"}}
                /> 
                
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Dynamic Video Uploads
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Easily upload and showcase your video CVs, ensuring an engaging presentation of your skills and personality.
                </p>
               
            </div>
            <div style={{padding: "4px" }} className="d-block d-lg-none">
                
                <img 
                    src={require("../assets/img/003-video-symbol-sketched-variant.png")} alt="browser window"
                
                    style={{ width: "50px"}}
                /> 
                
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Dynamic Video Uploads
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Easily upload and showcase your video CVs, ensuring an engaging presentation of your skills and personality.
                </p>
               
            </div>

            <div style={{padding: "20px" }} className="d-none d-lg-block">
                <img 
                    src={require("../assets/img/005-megaphone.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Ad-Free Experience
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Present your video CV in an ad-free environment. Recruiters won't be bombarded with ads while viewing your profile like on other platforms.
               </p>
            </div>   
            <div style={{padding: "4px", marginTop: "20px" }} className="d-block d-lg-none">
                <img 
                    src={require("../assets/img/005-megaphone.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Ad-Free Experience
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Present your video CV in an ad-free environment. Recruiters won't be bombarded with ads while viewing your profile like on other platforms.
               </p>
            </div>   
        </Col>


        <Col lg={4} style={{ padding: "20px"}} sm={12}>
            <div style={{padding: "20px"}} className="d-none d-lg-block">
                <img 
                    src={require("../assets/img/004-business-statistics-sketch.png")} alt="browser window"
                    className=""
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Insightful Analytics
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Gain valuable insights with comprehensive analytics, tracking views, engagement, and more to supercharge your video CV's impact.
                </p>
               
            </div>
            <div style={{padding: "0px 4px", marginTop: "-16px"}} className="d-block d-lg-none">
                <img 
                    src={require("../assets/img/004-business-statistics-sketch.png")} alt="browser window"
                    className=""
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Insightful Analytics
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Gain valuable insights with comprehensive analytics, tracking views, engagement, and more to supercharge your video CV's impact.
                </p>
               
            </div>

            <div style={{padding: "20px"}} className="d-none d-lg-block">
                <img 
                    src={require("../assets/img/001-personal-folder.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Enriched Profile Details
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Go beyond the video with additional profile information, crafting a comprehensive portrayal of your qualifications and experiences.
               </p>
            </div>   
            <div style={{padding: "4px", marginTop: "20px"}} className="d-block d-lg-none">
                <img 
                    src={require("../assets/img/001-personal-folder.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Enriched Profile Details
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Go beyond the video with additional profile information, crafting a comprehensive portrayal of your qualifications and experiences.
               </p>
            </div>   
        </Col>

        <Col lg={4} style={{ padding: "20px"}} sm={12}>
            <div style={{padding: "20px"}} className="d-none d-lg-block">
                <img 
                    src={require("../assets/img/002-world-wide-web.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Personalized Profile URL
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Create a distinct professional identity with a unique and tailored URL for effortless networking and memorable connections.
                </p>
               
            </div>
            <div style={{padding: "0px 4px", marginTop: "-16px"}} className="d-block d-lg-none">
                <img 
                    src={require("../assets/img/002-world-wide-web.png")} alt="browser window"
                    className="img centeredShadow"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Personalized Profile URL
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Create a distinct professional identity with a unique and tailored URL for effortless networking and memorable connections.
                </p>
               
            </div>


            <div style={{padding: "20px"}} className="d-none d-lg-block">
                <img 
                    src={require("../assets/img/006-graphic-tool.png")} alt="browser window"
                    className="img"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Intuitively Designed Platform
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Seamlessly navigate our platform with an intuitive and user-friendly design, simplifying the creation and management of your video CV.
               </p>
            </div>  
            <div style={{padding: "4px", marginTop: "20px"}} className="d-block d-lg-none">
                <img 
                    src={require("../assets/img/006-graphic-tool.png")} alt="browser window"
                    className="img"
                    style={{ width: "50px"}}
                /> 
                <p style={{ textAlign: "left", fontWeight: "900", padding: "10px 0px 10px 0px", margin: 0}}>
                Intuitively Designed Platform
                </p>
                <p style={{ textAlign: "left", padding: 0, margin: 0  }}>
                Seamlessly navigate our platform with an intuitive and user-friendly design, simplifying the creation and management of your video CV.
               </p>
            </div>   
        </Col>
        
       
       

        </Row>
       
        
    </Container>
        
   </>
  );
}

export default Features;
