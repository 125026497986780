import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faGithub, faInstagram, faTwitter, faLinkedin, faBehance } from '@fortawesome/free-brands-svg-icons';


function Screen() {

    return (
        <div style={{ width: "100%", marginTop: "100px", overflowX: "scroll"}} className="d-none d-lg-block">
            <p className="d-block d-lg-none">Scroll to the right >></p>
            <div style={{  display: "flex", width: "700px",marginTop: "100px", marginBottom: "200px", margin: "0 auto"}}>
                <div 
                    style={{ height: "100%", width: "50px", backgroundColor: "red", display: "block"}}
                    className="d-block d-lg-none"
                >
                    <div style={{ width: "24px"}}/>
                </div>
                <div className="Container border fade_in" style={{ overflowY: "hidden"}}>
                    <img 
                        src={require("../assets/img/browserBar.png")} alt="browser window"
                        className="img centeredShadow"
                        style={{ width: "700px"}}
                    /> 
                    <div className="screen">
                    <div className="left">
                        <div className="leftSubContainer glow">
                        <p className="exampleName fade_in" style={{ animationDelay: "2s"}}>Mike Smith</p>
                        <p className="exampleJob fade_in" style={{ animationDelay: "2.5s"}}>Digital Marketer</p>
                        <p className="exampleDescription fade_in" style={{ animationDelay: "3s"}}>
                            Recent digital marketing grad with a passion for strategy. Skilled in social media, SEO, and email marketing. Results-driven with a knack for analytics and communication.
                        </p>
                        <div className="split fade_in" style={{ animationDelay: "4s"}}>
                            <div className="exampleLocationContainer">
                            <p className="locationTitle">Location</p>
                            <p className="locationValue">NYC / remote</p>
                            </div>
                            <div className="exampleTimeZoneContainer">
                            <p className="locationTitle">Timezone</p>
                            <p className="locationValue">GMT-5</p>
                            </div>
                        </div>
                        <p className="socialsTitle fade_in" style={{ animationDelay: "5s"}}>Fine me on:</p>
                        <div className="socialsContainer fade_in" style={{ animationDelay: "5s"}}>
                            <p className="socialIcons"><FontAwesomeIcon icon={faWindowMaximize} /></p>
                            <p className="socialIcons"><FontAwesomeIcon icon={faEnvelope} /></p>
                            <p className="socialIcons"><FontAwesomeIcon icon={faGithub} /></p>
                            <p className="socialIcons"><FontAwesomeIcon icon={faInstagram} /></p>

                            <p className="socialIcons"><FontAwesomeIcon icon={faLinkedin} /></p>
                            <p className="socialIcons"><FontAwesomeIcon icon={faBehance} /></p>
                        </div>
                        </div>          
                    </div>
                    <div className="middle">
                        <video
                        style={{minWidth: "204px"}}
                        autoPlay
                        loop
                        className="video"
                        type="video/mp4"
                        poster={require("../assets/img/thumbnail.png")}
                        src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fshutterstock_1095785261%20(1).mov?alt=media&token=fc66b5ba-2638-4fa8-ba3d-78ce9466e90b"}
                        />
                    </div>
                    <div className="right">
                        <div className="rightSubContainer glow">
                        <p className="exampleTitleVideos fade_in" style={{ animationDelay: "5.2s"}}>Which video would you like to see?</p>
                        <div className="fade_in" style={{ animationDelay: "5.3s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)", opacity: "40%"}}
                            /> 
                            <p className="exampleVideoListRowText faint">Welcome! Allow me to introduce myself</p>
                            </div>
                        </div>
                        <div className="fade_in" style={{ animationDelay: "5.4s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)"}}
                            /> 
                            <p className="exampleVideoListRowText">What inspired me and how I got started in digital marketing</p>
                            </div>
                        </div>
                        <div className="fade_in" style={{ animationDelay: "5.5s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)"}}
                            /> 
                            <p className="exampleVideoListRowText">Let me walk you through a sucessful digital marketing campaign I have managed</p>
                            </div>
                        </div>
                        <div className="fade_in" style={{ animationDelay: "5.6s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)"}}
                            /> 
                            <p className="exampleVideoListRowText">How I stay up-to-date with the latest developments and trends</p>
                            </div>
                        </div>
                        <div className="fade_in" style={{ animationDelay: "5.7s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)"}}
                            /> 
                            <p className="exampleVideoListRowText">What metrics I typically track to measure the success of my campaigns</p>
                            </div>
                        </div>
                        <div className="fade_in" style={{ animationDelay: "5.8s" }}>
                            <div className="exampleVideoListRow">
                            <img 
                                src={require("../assets/img/play_button_large_green.png")} alt="browser window"
                                style={{ width: "15px", height: "15px", filter: "grayscale(100%)"}}
                            /> 
                            <p className="exampleVideoListRowText">Examples of a challange I encountered and how I overcame it</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
        
      

    );
}

export default Screen;